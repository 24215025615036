<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M10.4615 24C10.4615 16.5229 16.5229 10.4615 24 10.4615C31.4771 10.4615 37.5385 16.5229 37.5385 24C37.5385 31.4771 31.4771 37.5385 24 37.5385C16.5229 37.5385 10.4615 31.4771 10.4615 24ZM24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8ZM23.9996 16.6156C24.6793 16.6156 25.2304 17.1666 25.2304 17.8463V22.7705H30.1535C30.8332 22.7705 31.3843 23.3215 31.3843 24.0013C31.3843 24.681 30.8332 25.232 30.1535 25.232H25.2304V30.154C25.2304 30.8338 24.6793 31.3848 23.9996 31.3848C23.3199 31.3848 22.7688 30.8338 22.7688 30.154V25.232H17.8458C17.1661 25.232 16.615 24.681 16.615 24.0013C16.615 23.3215 17.1661 22.7705 17.8458 22.7705H22.7688V17.8463C22.7688 17.1666 23.3199 16.6156 23.9996 16.6156Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-new-item-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
